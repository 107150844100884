<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editUser') }}</h5>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.active') }}</label>
                    <div class="p-col-12 p-md-4">
                        <InputSwitch v-model="user.active" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.username') }}</label>
                    <div class="p-col-12 p-md-4">
                        <InputText style="width: 100%" type="text" v-model="user.username" @input="checkUsername" :disabled="this.isEdit()" @blur="checkUsernameAvailability()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.firstName') }} *</label>
                    <div class="p-col-12 p-md-4">
                        <InputText style="width: 100%" type="text" v-model="user.firstName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.lastName') }} *</label>
                    <div class="p-col-12 p-md-4">
                        <InputText style="width: 100%" type="text" v-model="user.lastName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.email') }} </label>
                    <div class="p-col-12 p-md-4">
                        <InputText style="width: 100%" type="text" v-model="user.emailAddress" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.roles') }} *</label>
                    <div class="p-col-12 p-md-4">
                        <MultiSelect style="width: 100%" v-model="rolesModel" optionLabel="displayName" :options="roles" :placeholder="$t('dropdown.placeholder.roles')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.regions') }} </label>
                    <div class="p-col-12 p-md-4">
                        <MultiSelect style="width: 100%" v-model="regionsModel" optionLabel="name" :options="regions" :placeholder="$t('dropdown.placeholder.regions')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.franchise') }} </label>
                    <div class="p-col-12 p-md-4">
                        <Dropdown :showClear="true" style="width: 100%" v-model="franchiseModel" optionLabel="name" :options="franchises" :placeholder="$t('dropdown.placeholder.franchise')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.shop') }} </label>
                    <div class="p-col-12 p-md-4">
                        <MultiSelect style="width: 100%" v-model="shopsModel" optionLabel="name" :options="shops" :placeholder="$t('dropdown.placeholder.shops')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <transition-group name="p-message" tag="div">
                    <Message :key="passwordKeyCount" :severity="'info'" v-show="successReset">{{ $t('system.alerts.passwordReset') }}</Message>
                </transition-group>
                <Button :label="$t('buttons.resetPassword')" class="p-button-raised p-button-info p-mr-2 p-mb-2" @click="resetUserPassword()" />

                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'users',
            errorKeyCount: 0,
            user: {
                active: false,
            },
            regionsModel: [],
            rolesModel: [],
            franchiseModel: '',
            roles: [],
            regions: [],
            franchises: [],
            successReset: false,
            passwordKeyCount: 0,
            shops: [],
            shopsModel: [],
        };
    },
    watch: {
        rolesModel() {
            this.showError = false;
        },
        franchiseModel() {
            this.showError = false;
        },
        regionsModel() {
            this.showError = false;
        },
        shopsModel() {
            this.showError = false;
        },
    },
    mounted() {
        this.BoApi.getRoles()
            .then((responseRole) => {
                this.roles = responseRole.data;
                this.BoApi.getRegionsDdl().then((responseRegions) => {
                    this.regions = responseRegions.data;
                    this.BoApi.getBUnitsDdl().then((responseFranchises) => {
                        this.franchises = responseFranchises.data;
                        this.BoApi.getShops('').then((responseShops) => {
                            this.shops = responseShops.data;
                            if (this.isEdit()) {
                                this.BoApi.getUser(this.$route.params.id).then((response) => {
                                    this.user = response.data;
                                    for (let i = 0; i < this.regions.length; i++) {
                                        if (this.user.regions.indexOf(this.regions[i]._id) >= 0) {
                                            this.regionsModel.push(this.regions[i]);
                                        }
                                    }

                                    for (let i = 0; i < this.roles.length; i++) {
                                        if (this.user.roles.indexOf(this.roles[i]._id) >= 0) {
                                            this.rolesModel.push(this.roles[i]);
                                        }
                                    }

                                    for (let i = 0; i < this.franchises.length; i++) {
                                        if (this.user.franchiseId === this.franchises[i]._id) {
                                            this.franchiseModel = this.franchises[i];
                                        }
                                    }
                                    for (let i = 0; i < this.shops.length; i++) {
                                        if (this.user.shops.indexOf(this.shops[i]._id) >= 0) {
                                            this.shopsModel.push(this.shops[i]);
                                        }
                                    }
                                });
                            }
                        });
                    });
                });
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.errorText = error;
                this.showError = true;
            });
    },
    methods: {
        submitForm: function () {
            this.showError = false;
            this.disableSubmit = true;
            if (this.notNullOrEmptyField(this.user.username) && this.notNullOrEmptyField(this.user.lastName) && this.notNullOrEmptyField(this.user.firstName) && this.notNullOrEmptyObject(this.rolesModel)) {
                if (
                    (this.notNullOrEmptyObject(this.regionsModel) && this.notNullOrEmptyObject(this.franchiseModel)) ||
                    (this.notNullOrEmptyObject(this.franchiseModel) && this.notNullOrEmptyObject(this.shopsModel)) ||
                    (this.notNullOrEmptyObject(this.regionsModel) && this.notNullOrEmptyObject(this.shopsModel))
                ) {
                    this.errorText = this.$t('system.alerts.bothregionandfranchise');
                    this.showError = true;
                    this.disableSubmit = false;
                    this.errorKeyCount++;
                } else {
                    if (this.notNullOrEmptyObject(this.regionsModel) || this.notNullOrEmptyObject(this.franchiseModel) || this.notNullOrEmptyObject(this.shopsModel)) {
                        this.user.regions = this.regionsModel.map((item) => {
                            return item._id;
                        });
                        this.user.shops = this.shopsModel.map((item) => {
                            return item._id;
                        });
                        this.user.roles = this.rolesModel.map((item) => {
                            return item._id;
                        });
                        this.user.franchiseId = this.franchiseModel === '' ? null : this.franchiseModel._id;
                        this.BoApi.saveUser(this.user)
                            .then(() => {
                                this.goto(this.backRoute);
                            })
                            .catch((error) => {
                                this.disableSubmit = false;
                                this.checkForUnauthorizedError(error);
                                this.showError = true;
                                this.errorText = error;
                                this.errorKeyCount++;
                            });
                    } else {
                        this.errorText = this.$t('system.alerts.noregionorfranchise');
                        this.showError = true;
                        this.disableSubmit = false;
                        this.errorKeyCount++;
                    }
                }
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        checkUsernameAvailability() {
            let user = { username: this.user.username };
            this.BoApi.isUsernameAvailable(user)
                .then(() => {
                    this.showError = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response && error.response.status === 409) {
                        this.showError = true;
                        this.errorText = this.$t('system.alerts.usernameTaken');
                        this.errorKeyCount++;
                    }
                });
        },
        resetUserPassword() {
            this.BoApi.resetUserPassword(this.user._id)
                .then(() => {
                    this.successReset = true;
                    this.passwordKeyCount++;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response && error.response.status === 409) {
                        this.showError = true;
                        this.errorText = this.$t('alerts.generalError');
                        this.errorKeyCount++;
                    }
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        checkUsername: debounce(function () {
            this.checkUsernameAvailability();
        }, 800),
    },
};
</script>
